@import "../variables";

* {
  font-family: $font-family-primary;
}

.home-promotion-container {
  margin-top: 10rem !important;
  background-color: #005249;
  color: #ffffff;
  position: relative;
  position: relative;
  .container {
    height: 100%;
    .row {
      height: 100%;

      .custom-promo-image {
        height: 100%;
        .image-promo-container {
          height: 710px;
          margin-top: -5rem;
          padding-bottom: 2rem;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
            padding: 0 1vw;
            transition: transform 0.3s ease;

            &:hover {
              transform: scale(1);
            }
          }
        }
      }

      .right-content-propotion {
        display: flex;
        flex-direction: column;
        padding-left: 3vw;
        margin-top: 6vw;
        justify-content: start;

        h2 {
          font-size: 2.4rem;
          font-weight: 700;
        }

        p {
          font-size: 1.1rem;
          line-height: 26px;
          margin-top: 1rem;
        }

        .btn-promo {
          padding: 12px 15px;
          background-color: azure;
          color: #005249;
          font-weight: bold;
          width: 18rem;
          font-size: 1.2rem;
          border-radius: 0;
          border-color: #005249;
          &:hover {
            color: #fff;
            background-color: #005249;
            border-color: azure;
          }
        }
      }
    }
  }
}

.home-promotion-container {
  @include media-breakpoint-down("lg") {
    height: auto;
    width: 100%;
    margin-top: 8rem !important;
    .container {
      width: 100%;
      .row {
        height: 100%;
        padding: 2rem 0rem;

        .custom-promo-image {
          height: 100%;
          width: 80%;
          .image-promo-container {
            margin-top: -6rem;
            padding: 2vw 0;
            height: 700px;
            width: 100%;
          }
        }
        .right-content-propotion {
          height: auto;
          width: 90%;
          margin-top: 2rem;

          p {
            font-size: 1.1rem;
            margin-top: 0;
            margin-top: 1rem;
          }
          .btn-promo {
            width: 16rem;
            padding: 5px 15px;
            margin-top: 1rem;
          }
        }
      }
    }
  }
}

.home-promotion-container {
  @include media-breakpoint-down("md") {
    height: auto;
    margin-top: 10rem !important;

    .container {
      .row {
        .custom-promo-image {
          width: 100%;
          .image-promo-container {
            height: 610px;
            padding: 2vw 2vw;
            margin-top: -7rem;
          }
        }
        .right-content-propotion {
          margin-top: 2rem;
          justify-content: space-around;
          width: 100%;
          padding: 0vw 5vw;
          h2 {
            font-size: 2.5rem;
            margin-top: 1rem;
          }

          p {
            font-size: 1.2rem;
            margin-top: 1rem;
          }
          .btn-promo {
            font-size: 1.3rem;
            margin: 1rem 0;
            padding: 7px 15px;
            width: 20rem;
          }
        }
      }
    }
  }
}

.home-promotion-container {
  @include media-breakpoint-down("sm") {
    height: auto;

    .container {
      .row {
        .custom-promo-image {
          width: 100%;
          .image-promo-container {
            width: 100%;
            height: 100%;
          }
        }

        .right-content-propotion {
          padding: 0 6vw;
          justify-content: space-evenly;

          .btn-promo {
            width: 15rem;
          }
        }
      }
    }
  }
}
