// customModal.scss
@import "../variables";

.custom-modal-wrapper {
  position: relative;

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 99999;
  }

  .custom-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 60px 40px 40px 40px;
    z-index: 99999;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    &::-webkit-scrollbar {
      width: 0;
    }

    @include breakpoint(1300px) {
      width: 35%;
    }

    @include breakpoint(1450px) {
      width: 27%;
    }

    @include media-breakpoint-down("xl") {
      width: 35%;
      padding: 40px 20px 30px 20px;
    }

    @include media-breakpoint-down("md") {
      width: 100%;
      padding: 40px 20px;
    }

    .modal-content {
      position: unset;

      .close {
        position: absolute;
        top: 10px;
        right: 16px;
        font-size: 38px;
        width: 32px;
        height: 32px;
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;

        &:focus {
          &:focus {
            outline: 2px dashed #000;
            box-shadow: none;
          }
        }

        .cross-icon {
          font-size: 32px;
          opacity: 0.5;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      h1 {
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 1px;
      }

      .heading-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-bottom: 30px;

        .heading {
          font-size: 28px;
          font-weight: 600;
          letter-spacing: 1px;
          text-align: center;
        }

        .description {
          font-size: 15px;
          letter-spacing: 0.6px;
          text-align: center;
          color: #000;

          @include breakpoint(992px) {
            width: 80%;
            margin: auto;
          }

          @include breakpoint(1500px) {
            width: 95%;
          }
        }

        .create-account-description {
          font-size: 15px;
          letter-spacing: 0.6px;
          text-align: center;
          color: #000;
        }
      }
    }
  }

  .custom-login-modal {
    overflow-y: scroll;

    @include breakpoint(0px) {
      height: 100%;
    }

    @include breakpoint(768px) {
      height: auto;
    }

    @include breakpoint(1300px) {
      height: 100%;
    }

    @include breakpoint(1500px) {
      height: auto;
    }
  }

  .custom-create-account-modal {
    overflow-y: scroll;

    @include breakpoint(0px) {
      height: 100%;
    }

    @include breakpoint(768px) {
      height: auto;
    }

    @include breakpoint(1300px) {
      height: 100%;
    }

    @include breakpoint(1500px) {
      height: auto;
    }
  }

  .custom-forgot-password-modal {
    height: auto;
  }
}
