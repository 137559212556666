@import "../../../variables";

.password-title-conatiner {
    padding: 12rem 0 0 0;
    background: transparent;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .password-edit-heading {
        color: white;
        background-color: black;
        text-transform: uppercase;
        text-align: right;
        letter-spacing: 1px;
        margin: 0;

        @include breakpoint(0px) {
            font-size: 18px;
            padding: 15px 20px;
            max-width: 220px;
        }

        @include breakpoint(769px) {
            font-size: 24px;
            max-width: 300px;
            padding: 25px 30px;
        }
    }
}

.user-password-form-data-container {
    width: 100%;
    padding: 25px 0 100px 0;

    .user-password-form-wrapper {
        margin: auto;

        @include breakpoint(1300px) {
            width: 45%;
        }

        @include media-breakpoint-down("xl") {
            width: 55%;
        }

        @include media-breakpoint-down("md") {
            width: 100%;
        }

        .user-password-form {
            @include breakpoint(0px) {
                padding: 0 12px;
            }

            @include breakpoint(769px) {
                padding: 20px;
            }

            .current-password-container,
            .new-password-container,
            .confirm-new-password-container {
                position: relative;
                margin-bottom: 24px;

                .lable-password {
                    font-size: 14px;
                    letter-spacing: 0.5px;
                    font-weight: 500;
                    color: #000;
                }

                .user-password-input {
                    height: 40px;
                    border-radius: 0;
                    border: 1px solid #767676;
                }

                .password-toogle-icon {
                    position: absolute;
                    top: 8px;
                    right: 10px;
                    z-index: 1000;
                    cursor: pointer;
                }

                .custom-password-icon {

                    top: 40px;
                    right: 8px;

                    .custom-icon-eye {
                        width: 18px;
                        height: 18px;
                        color: #767676;
                    }
                }
            }

            .button-container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin: 20px auto;

                @include breakpoint(1300px) {
                    flex-direction: row;
                    gap: 50px;
                }

                @include media-breakpoint-down("xl") {
                    flex-direction: column;
                    gap: 20px;
                }

                @include media-breakpoint-down("md") {
                    flex-direction: column;
                    gap: 10px;
                }

                .secondary-btn {
                    border: 2px solid #DFDFDF;
                    text-transform: uppercase;

                    @include breakpoint(1300px) {
                        width: 45%;
                    }

                    @include media-breakpoint-down("xl") {
                        width: 70%;
                    }

                    @include media-breakpoint-down("md") {
                        width: 70%;
                    }
                }

                .primary-btn {
                    border: 2px solid #DFDFDF;
                    text-transform: uppercase;

                    @include breakpoint(1300px) {
                        width: 45%;
                    }

                    @include media-breakpoint-down("xl") {
                        width: 70%;
                    }

                    @include media-breakpoint-down("md") {
                        width: 70%;
                    }

                }

            }

            .myAccount-link-container {
                display: flex;
                align-items: center;

                .common-customer-detail {
                    align-items: center;
                }
            }
        }
    }
}