@import "../../variables";

.product-detail {
  width: 100%;
  padding: 0 20px;
  margin: auto;

  @include media-breakpoint-down("md") {
    padding: 0;
  }

  .product-detail-heading {
    font-size: 26px;
    color: #000;
    font-weight: 600;
    letter-spacing: 1.2px;

    @include media-breakpoint-down("md") {
      font-size: 18px;
      margin: 20px auto;
    }
  }

  .product-prices {
    display: flex;
    flex-direction: column;
    margin: 25px 0;

    .product-price {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 40px;

      .current-price {
        font-size: 22px;
        letter-spacing: 0.8px;
        font-weight: 600;
        color: #000;

        @include media-breakpoint-down("md") {
          font-size: 16px;
          letter-spacing: 0.5px;
        }
      }

      .tax-label {
        font-size: 14px;
        letter-spacing: 0.8px;
        color: #000;
        font-weight: 600;
        text-decoration: underline;

        @include media-breakpoint-down("md") {
          letter-spacing: 0.5px;
        }
      }
    }

    .tax-shipping-delivery-label {
      .delivery-information {
        font-size: 14px;
        letter-spacing: 0.8px;
        color: #878787;

        @include media-breakpoint-down("md") {
          letter-spacing: 0.5px;
        }
      }
    }
  }

  .product-description {
    margin-bottom: 30px;
    text-align: justify;

    p {
      margin: 0;
      text-align: justify;
      font-size: 14px;
      letter-spacing: 0.8px;
      color: #000;
      font-weight: 400;

      @include media-breakpoint-down("md") {
        letter-spacing: 0.5px;
      }
    }
  }

  .product-action {
    width: 100%;

    form {
      .product-variants {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .product-variants-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 60px;

          @include media-breakpoint-down("md") {
            gap: 35px;
          }

          .size-lable-container {
            .size-label {
              font-size: 14px;
              letter-spacing: 0.8px;
              text-transform: uppercase;
              font-weight: 700;
              margin: auto 10px auto 0;
              min-width: 70px;
            }
          }

          .select-size-container {
            display: flex;
            flex-direction: row;
            gap: 20px;

            .TextSelectorButtonWrapper {
              position: relative;

              .product-button-size {
                padding: 4px;
                width: 40px;
                height: 30px;
                border: 1px solid #ccc;
                border-radius: 4px;
                cursor: pointer;
                text-transform: lowercase;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                &.selected {
                  background: black;
                  color: white;
                }

                &.unavailable {
                  cursor: pointer;
                  color: #000;
                  position: relative;
                  background-color: white;

                  &::before,
                  &::after {
                    content: "";
                    position: absolute;
                    width: 2px; // Adjust the width of the diagonal line
                    background-color: black; // Adjust the color of the diagonal line
                    transform-origin: top left;
                  }

                  &::before {
                    top: 4px;
                    left: 8px;
                    height: 100%; // Adjust the length of the diagonal line
                    transform: rotate(-45deg); // Rotate the line to form the diagonal effect
                  }
                }
              }
            }
          }
        }

        .product-variants-items {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 25px;

          @include media-breakpoint-down("md") {
            gap: 0;
          }

          .color-lable-container {
            .color-label {
              display: block;
              font-size: 14px;
              letter-spacing: 0.8px;
              text-transform: uppercase;
              font-weight: 700;
              margin: auto 10px auto 0;
              min-width: 70px;
            }
          }

          .select-color-container {
            display: flex;
            flex-direction: row;
            gap: 20px;

            .color-button {
              width: 30px;
              height: 30px;
              border-radius: 50px;
              overflow: hidden;
              border: none;

              &.selected {
                outline: 1px solid #000;
                outline-offset: 2px;
              }

              &:focus {
                outline: 1px solid #000;
                outline-offset: 2px;
              }

              .color-picker {
                opacity: 1;
                width: 70px;
                height: 70px;
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #0000001a;
                padding: 0;
                margin: -30px 0 0 -30px;
                border: none;
              }
            }
          }
        }

        .no-item {
          padding-left: 18%;
          font: 400 1.2rem "Roboto";
          color: red;
        }

        .custom-select-container {
          width: 100%;
          height: auto;
          border-radius: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 25px;
          z-index: 2;

          @include media-breakpoint-down(md) {
            gap: 0;
          }

          .custom-select-label {
            letter-spacing: 0.8px;
            font-size: 14px;
            font-weight: 700;
            min-width: 70px;
            margin: auto 10px auto 0;
            text-transform: uppercase;
          }

          .custom-select-input-box {
            width: 25%;
            height: 36px;
            border-radius: 0;
            justify-content: space-between;

            @include media-breakpoint-down(lg) {
              width: 40%;
            }

            @include media-breakpoint-down(md) {
              width: 25%;
            }

            .selected-option-text {
              font-size: 13px;
              letter-spacing: 1px;
            }

            .custom-dropdown-icon {
              font-size: 13px;
            }

            .custom-options-list {
              top: 100%;
              height: auto;

              &::-webkit-scrollbar {
                width: 5px;
              }

              &::-webkit-scrollbar-thumb {
                background-color: #888;
              }

              &::-webkit-scrollbar-track {
                background-color: #f1f1f1;
              }

              .custom-options {
                padding: 8px;
                font-size: 13px;
                letter-spacing: 1px;
              }
            }
          }
        }

        .product-item-review {
          display: flex;
          flex-direction: row;
          gap: 30px;
          align-items: center;

          @include media-breakpoint-down("md") {
            gap: 0;
          }

          .review-label-container {
            .review-label {
              display: block;
              font-size: 14px;
              letter-spacing: 0.8px;
              text-transform: uppercase;
              font-weight: 700;
              margin: auto 10px auto 0;
              min-width: 70px;
            }
          }

          .product-star {
            display: flex;
            flex-direction: row;
            gap: 20px;
            font-size: larger;

            .fa-star {
              font-size: 0.9rem;
              color: #daa520;
            }
          }
        }

        .product-add-to-cart {
          display: flex;
          flex-direction: row;
          width: 100%;

          .add-to-cart {
            font-size: 14px;
            width: 90%;
            letter-spacing: 1.6px;
            line-height: 24px;
            height: 42px;
            border-radius: 6px;
            text-transform: uppercase;
            color: WHITE;
            background: BLACK;
            margin: auto;
            justify-content: center;

            &:hover {
              border: 1px solid BLACK;
              background: WHITE;
              color: BLACK;
            }

            &:focus {
              outline: 2px dashed BLACK;
              outline-offset: 6px;
            }
          }

          .add-to-wishlist {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 42px;
            height: 42px;

            .whishlist-icon {
              width: 30px;
              height: 30px;
              color: #000;

              &:hover {
                background: black;
                color: white;
                font-weight: bold;
              }

              &.wishlist-clicked {
                background: black;
                color: white;
              }
            }
          }
        }

        .break-line::after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          opacity: 0.5;
          background: #333 !important;
          margin: 10px 0px 20px 0px;
        }
      }

      .out-of-stock-text {
        color: #ed0303;
        font-size: 18px;
        letter-spacing: 0.5px;
        font-weight: 500;
        margin-bottom: 0;
        padding: 0em;
        padding-left: 100px;
        border: none;
      }
    }
  }

  .add-review {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0;
    margin: 0;

    .reviews-adding-area {
      display: flex;
      flex-direction: column;

      .reviews-headding {
        font-size: 14px;
        letter-spacing: 0.8px;
        font-weight: 700;
        text-transform: uppercase;
      }

      .review-text {
        font-size: 14px;
        letter-spacing: 0.8px;
        color: #000;
        font-weight: 400;

        @include media-breakpoint-down("md") {
          letter-spacing: 0.5px;
        }
      }
    }

    .add-review-button {
      font-size: 14px;
      letter-spacing: 1.6px;
      line-height: 24px;
      height: 42px;
      border-radius: 6px;
      text-transform: uppercase;
      color: BLACK;
      background: WHITE;
      justify-content: center;

      &:hover {
        border: 1px solid BLACK;
        background: BLACK;
        color: WHITE;
      }

      &:focus {
        outline: 2px dashed BLACK;
        outline-offset: 6px;
      }

      @include breakpoint(0px) {
        width: 90%;
        margin: auto;
      }

      @include breakpoint(769px) {
        width: 90%;
        margin: auto;
      }
    }
  }
}