@import "../variables";

.product-slider {
  margin: 4rem auto;
  overflow: hidden;

  .slider-container {
    .slick-list {
      .slick-slide {
        padding: 0px 15px;
        position: relative;
        background-color: #f8f9fa;
        transition: transform 0.3s ease;

        img {
          height: 400px;
          object-fit: cover;
          width: 100%;
          position: relative;
          transition: transform 0.3s ease;
          transition: transform 0.3s ease;

          &:hover {
            transform: scale(1.04);
          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.3) 0%,
              rgba(0, 0, 0, 0) 100%
            );
            z-index: 1;
            opacity: 0;
            transition: opacity 0.3s ease;
          }

          &:hover::before {
            opacity: 1;
          }
        }

        &:hover {
          box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
        }

        .product-details {
          width: 100%;
          padding: 10px 0px;
          text-align: start;

          h5 {
            color: #003867;
            font-weight: 600;
            padding: 4px 0px;
          }

          .crousel-description-wrapper {
            position: relative;
            bottom: 0;
            left: 0;
            width: 100%;
            transition: transform 0.3s linear;
            height: 100%;

            .list-group {
              display: flex;
              flex-direction: column;
              gap: 4px;

              .custom-color {
                font-size: 1.125em;
                color: #003867;
                font-weight: 700;
              }

              .list-group-item {
                font-size: 14px;
                padding: 0;
                border: none;
                background-color: #f8f9fa;

                .bold-span {
                  font-size: 1.125em;
                  color: #003867;
                  font-weight: 700;
                }

                .normal-span {
                  margin-left: 2px;
                  font-size: 1rem;
                  font-weight: normal;
                  color: #222;
                  text-overflow: ellipsis;
                  margin-top: 0;

                  .slider-anchor {
                    color: #003867;
                    font-weight: 500;

                    &:hover {
                      font-weight: 600;
                    }
                  }
                }

                .star-rating {
                  display: flex;
                  justify-content: center;

                  svg {
                    color: #333;
                    font-size: 12px;
                    margin-right: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &:hover .slick-slide {
      box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
    }
  }

  .carousel-control-next {
    display: inline-block;
    width: 1%;
    right: 1%;
  }

  .carousel-control-prev {
    display: inline-block;
    width: 1%;
    left: 1%;
  }

  .custom-arrow {
    color: #3498db;
    font-size: 32px;
    transition: color 0.3s ease;

    &:hover {
      color: #003867;
    }
  }
}

.product-slider {
  @include media-breakpoint-down("md") {
    .slider-container {
      .slick-list {
        .slick-slide {
          img {
            width: 95%;
            height: 475px;
            margin: 0px auto;
          }

          .product-details {
            width: 95%;
            margin: 0px auto;
          }
        }
      }
    }

    .carousel-control-next {
      width: 5%;
    }

    .carousel-control-prev {
      width: 5%;
    }
  }
}
