@import "../../variables";

.thankyou-message-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 26px;

  .thankyou-heading-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;

    .thankyou-text {
      font-size: 28px;
      color: #000;
      letter-spacing: 1px;
      line-height: 16px;
      text-transform: uppercase;
      font-weight: 700;
    }

    .description-text {
      font-size: 16px;
      color: #000;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  .check-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .check-mark-icon {
      width: 90px;
      height: 90px;
      color: rgb(22, 198, 12);
    }
  }

  .thankyou-notification-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    // margin: 40px auto auto auto;

    .recieve-text {
      width: 35%;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: 0.5px;
      color: #000;
      text-align: center;
      margin-bottom: 0;

      @include breakpoint(0px) {
        width: 80%;
        margin: auto;
        margin-bottom: 16px;
      }

      @include breakpoint(992px) {
        width: 35%;
        margin: auto;
        margin-bottom: 10px;
      }
    }

    .order-text {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.8px;
      color: #000;
      text-align: center;
      margin-bottom: 0;
    }
  }
}
