.loading {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: grid;
  place-items: center;
  max-width: 100%;
  div {
    width: 10vmax;
    height: 10vmax;
    border-radius: 50%;
    border-bottom: 5px solid rgba(0, 0, 0, 0.719);
    animation: loadingRotate 800ms linear infinite;
  }
}
@keyframes loadingRotate {
  to {
    transform: rotateZ(-360deg);
  }
}
