.cart-form {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;

  .brand-name {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 20px;
    color: black;
    font-weight: 600;
    letter-spacing: 5px;
  }

  .form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    label {
      font-weight: bold;
      width: 30%;
    }

    .input-group {
      display: flex;

      input {
        padding: 5px;
        margin-right: 10px;
        width: 70%;
        border: 1px solid #ccc;
        border-radius: 4px;
      }

      .apply-button {
        padding: 5px 10px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }

  .start-checkout-button {
    padding: 10px 20px;
    background-color: black;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    width: 100%;
    border: 1px solid black;
    &:hover {
      background-color: white;
      color: black;
      font-weight: 600;
    }
  }
}
