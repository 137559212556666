@import "../../variables";

.devider {
  width: 100%;
  margin-top: 80px;

  @include breakpoint(0px) {
    margin-top: 40px;
    margin-bottom: 0;
  }

  @include breakpoint(768px) {
    margin-top: 60px;
    margin-bottom: 1rem;
  }
}
