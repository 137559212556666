@import "../../variables";

.checkout-create-account-container {
  width: 100%;
  margin-top: 40px;

  ._account-heading-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    .__account-heading {
      font-size: 1rem;
      font-weight: 600;
      line-height: 16px;
      color: #242424;
      letter-spacing: 0.5px;
    }
  }

  ._button-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;

    .create-account-button,
    .as-guest-button {
      height: 54px;
      font-size: 15px;
      letter-spacing: 3px;
      text-transform: uppercase;
      border: 1px solid #d7d7d7;
    }
  }

  .terms-conditions-container {
    width: 100%;
    display: flex;
    margin-top: 40px;

    .terms-conditions-text {
      text-align: center;
      font-size: 11.5px;
      letter-spacing: 0.5px;
      color: #000;
      line-height: 18px;

      .link {
        text-decoration: underline;
        color: #000;
      }
    }
  }
}
