.common-select {
  position: relative;

  .form-label {
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0;
  }

  .custom-select {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    border: 1px solid rgb(227, 227, 227);
    padding: 0.375rem 0.75rem;

    .selected-option {
      flex-grow: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #999;
    }

    .dropdown-icon {
      color: #999;
      transition: transform 0.3s ease;
    }

    &.open {
      .dropdown-icon {
        transform: rotate(180deg);
      }
    }

    &:focus {
      outline: none;
      box-shadow: rgba(118, 118, 118, 0.8) 0px 0px 0px 2px;
    }

    &:focus {
      &.open {
        .dropdown-icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  .options-list {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    list-style: none;
    padding: 0;
    margin: 0;

    .option {
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease;

      &:hover,
      &:focus {
        &:hover {
          background-color: #f4f4f4; // Professional hover background color
          color: #333; // Adjust text color for better contrast
        }
      }
    }
  }
}
