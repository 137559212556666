.color-options {
  display: flex;
  gap: 8px;
  align-items: center;

  .color-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 26px;
    border-radius: 100%;
    height: 26px;
    &:hover {
      .color-name {
        text-decoration: underline;
      }
    }
    box-shadow: 0 2px 2px #ccc;
  }
  .selected {
    border: 1px solid black;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    outline-offset: 2px;
  }
}
