@import "../variables";
.newProductContainer {
  width: calc(100% - 250px);
  // border: 2px solid red;
  box-sizing: border-box;
  border-left: 1 solid rgba(0, 0, 0, 0.158);
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  left: 250px;
  position: relative;
  min-height: calc(100svh - 93px);
  background-color: rgb(235, 235, 235);
  #newProductHeading {
    font: 400 2.6vmax "Roboto";
    box-sizing: border-box;
    color: #f58621;
    transform: all 0.5s;
    margin: 0.3rem 1.5rem;
    text-align: center;

    @include media-breakpoint-down("md") {
      font: 400 3vmax "Roboto";
    }
  }

  .createProductForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.7rem auto;
    padding: 2vmax;
    justify-content: space-evenly;
    height: 100%;
    width: 60vh;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.267);
    gap: 0.8vmax;
    // border: 2px solid;
    @include media-breakpoint-down("xl") {
      width: 75%;
      height: auto;
    }

    @include media-breakpoint-down("lg") {
      width: 70%;
    }
    @include media-breakpoint-down("md") {
      padding: 3vmax;
      width: 70%;
    }
    @include media-breakpoint-down("sm") {
      padding: 3vmax;
      width: 92%;
    }
  }

  .createProductForm > .product-content {
    display: flex;
    width: 100%;
    align-items: center;

    svg {
      position: absolute;
      transform: translateX(1vmax);
      font-size: 1.6vmax;
      color: rgba(0, 0, 0, 0.623);

      @include media-breakpoint-down("md") {
        font-size: 2.8vmax;
      }
    }
  }

  .createProductForm > .product-content > .create-product-input-data,
  .createProductForm > .product-content > .product-select-field,
  .createProductForm > .product-content > .product-text-field {
    padding: 15px 3vmax;
    padding-right: 1vmax;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.367);
    border-radius: 4px;
    font: 300 0.9vmax cursive;
    outline: none;
    text-align: start;

    @include media-breakpoint-down("xl") {
      padding: 1.5vmax 5vmax;
      font: 300 1.2vmax cursive;
    }
    @include media-breakpoint-down("lg") {
      padding: 1.5vmax 5vmax;
      font: 300 1.2vmax cursive;
    }

    @include media-breakpoint-down("md") {
      padding: 2.2vmax 5vmax;
      font: 300 1.5vmax cursive;
    }
  }

  .createProductForm > .product-content-stock {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .stock-content-conatiner {
      width: 33%;
      text-align: start;
      position: relative;

      .product-stock-fields {
        padding: 10px 3vmax;
        padding-right: 1vmax;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.367);
        border-radius: 4px;
        font: 300 0.9vmax cursive;
        outline: none;
        text-align: start;

        @include media-breakpoint-down("xl") {
          padding: 1.2vmax 3vmax;
          font: 200 1.5vmax cursive;
        }
        @include media-breakpoint-down("lg") {
          padding: 1vmax 2.2vmax;
          font: 300 1.3vmax cursive;
        }

        @include media-breakpoint-down("md") {
          padding: 1.5vmax 30px;
          font: 300 1.5vmax cursive;
        }
      }

      svg {
        position: absolute;
        transform: translateX(1vmax);
        font-size: 1.8vmax;
        color: rgba(0, 0, 0, 0.623);
        top: 50%;
        left: 5%;
        transform: translateY(-50%);

        @include media-breakpoint-down("md") {
          font-size: 2vmax;
        }
      }

      @include media-breakpoint-down("md") {
        white-space: wrap;
        width: 49%;
        margin-bottom: 5px;
        border: none;
        &:nth-last-child(1) {
          width: 100%;

          input {
            padding: 1.5vmax 5.5vmax;
            font: 300 1.5vmax cursive;
          }
          svg {
            font-size: 2.8vmax;
            left: 2%;
          }
        }
      }
    }

    @include media-breakpoint-down("md") {
      flex-wrap: wrap-reverse;
      padding: 0.5vmax 0.2vmax;
      outline: none;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
    }
  }

  .create-product-stock {
    width: 100%;
    border: none;
    font: 300 1vmax "Roboto";
    width: 100%;
    padding: 1.2vmax;
    cursor: pointer;
    transition: all 0.5s;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
    height: 3.5vmax;

    @include media-breakpoint-down("xl") {
      font-size: 1.8vmax;
      height: 4.5vmax;
    }
    @include media-breakpoint-down("lg") {
      font-size: 1.5vmax;
      height: 4.5vmax;
    }
    @include media-breakpoint-down("md") {
      font: 300 1.8vmax "Roboto";
      height: 5vmax;
    }
  }

  #createProductFormFile > input {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.367);
    border-radius: 4px;
    outline: none;
    text-align: start;

    &::file-selector-button {
      cursor: pointer;
      width: 100%;
      z-index: 2;
      border: none;
      margin: 0;
      font: 400 1.2vmax "Roboto";
      transition: all 0.2s;
      padding: 1vmax 0.5vmax;
      color: rgba(0, 0, 0, 0.623);
      background-color: rgb(235, 235, 235);

      &:hover {
        background-color: rgba(75, 75, 75, 0.671);
        color: white;
        font-weight: bold;
      }
      @include media-breakpoint-down("xl") {
        font-size: 1.8vmax;
        padding: 0.8vmax 0.5vmax;
      }
      @include media-breakpoint-down("lg") {
        font-size: 1.5vmax;
      }

      @include media-breakpoint-down("md") {
        font: 300 1.8vmax "Roboto";
        padding: 1.3vmax 0;
      }
    }
  }

  #createProductFormImage {
    width: 100%;
    overflow: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    img {
      width: 3vmax;
      margin: 0 0.5vmax;
      margin-bottom: 0.5vmax;
      height: 3vmax;

      @include media-breakpoint-down("lg") {
        width: 5vmax;
        height: 5vmax;
      }

      @include media-breakpoint-down("md") {
        width: 7vmax;
        height: 7vmax;
        border-radius: 100%;
      }
    }
  }

  #createProductBtn {
    border: none;
    background-color: #f58621;
    color: white;
    font: 300 1.2vmax "Roboto";
    width: 100%;
    padding: 1vmax;
    cursor: pointer;
    transition: all 0.5s;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);

    &:hover {
      background-color: #b35d0c;
    }
    @include media-breakpoint-down("xl") {
      font-size: 1.8vmax;
    }

    @include media-breakpoint-down("lg") {
      font-size: 1.5vmax;
    }

    @include media-breakpoint-down("md") {
      font: 300 1.8vmax "Roboto";
      padding: 1.4vmax 0;
    }
  }

  @include media-breakpoint-down("md") {
    border-left: none;
    width: calc(100% - 5%);
    left: 2.5%;
    margin-top: 5vmax;
    justify-content: center;
  }
}
