@import "../../../variables";

.other-signin-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .social-links-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .signin-with-text-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 10px;

      p {
        font-size: 14.5px;
        letter-spacing: 0.5px;
        color: rgb(0, 0, 0);
      }
    }
  }

  .register-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .links-icons-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 40px;

      @include breakpoint(1450px) {
        gap: 16px;
      }

      .instagram-btn {
        width: 28%;
        height: 48px;

        @include breakpoint(1450px) {
          width: 30%;
        }

        .--icon {
          color: rgb(0, 0, 0);
          font-size: 26px;
        }
      }
    }

    .register-text-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 5px;

      .register-text {
        font-size: 14.5px;
        letter-spacing: 0.5px;
        color: rgb(0, 0, 0);
        margin-bottom: 0;
      }

      .register-link {
        font-size: 13px;
        letter-spacing: 0.5px;
        color: rgb(0, 0, 0);
      }
    }

    .terms-conditions-container {
      width: 100%;
      display: flex;

      .terms-conditions-text {
        text-align: center;
        font-size: 11.5px;
        letter-spacing: 0.5px;
        color: #000;
        line-height: 18px;

        .link {
          text-decoration: underline;
          color: #000;
        }
      }
    }
  }
}
