@import "../../variables";

.product-list-page {
  margin-top: 82px;
}

.show-more-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Adjust height as needed */
  }

  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 30px;
    height: 30px;
    animation: spin 0.7s linear infinite;
    text-align: center;
    margin: auto;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.show-more-btn {
  width: 15%;
  font-size: 14px;
  letter-spacing: 1px;

  @include media-breakpoint-down(lg) {
    width: 50%;
    font-size: 16px;
  }

  @include media-breakpoint-down(md) {
    width: 80%;
    font-size: 18px;
  }
}
