@import "../../variables";

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    @include breakpoint(0px) {
        padding: 0 16px;
    }

    @include breakpoint(769px) {
        padding: 0;
    }

    .popup-content {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        max-width: 640px;
        width: 100%;
        // height: 500px;

        @include breakpoint(0px) {
            height: 450px;
        }

        @include breakpoint(769px) {
            height: 500px;
        }

        .review-heading-section {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .remove-item-button {
                margin: 0px;
                padding: 0px;
                outline: none;
                border: none;
                border-radius: 0px;
                background: transparent;
                color: rgb(0, 0, 0);
                font-size: 0.8125rem;
                line-height: 1.85;
                letter-spacing: 2.6px;
                font-family: AzoSansRegular;
                display: flex;
                width: 16px;
                height: 16px;

                .remove-item-icon {
                    opacity: 1;
                    transition: opacity 150ms ease-out 0s;
                    width: auto;
                    height: 100%;
                }
            }

            .review-heading {
                color: #000;
                font-weight: 600;
                letter-spacing: 1.2px;
                margin: 0;


                @include breakpoint(0px) {
                    font-size: 22px;
                    padding-bottom: 10px;
                }

                @include breakpoint(769px) {
                    font-size: 26px;
                    padding-bottom: 20px;
                }

            }
        }

        .rating-section {
            display: flex;
            flex-direction: row;

            @include breakpoint(0px) {
                padding-bottom: 10px;
            }

            @include breakpoint(769px) {
                padding-bottom: 20px;
            }

            .review-label {
                display: block;
                font-size: 14px;
                letter-spacing: 0.8px;
                text-transform: uppercase;
                font-weight: 700;
                margin: auto 10px auto 0;
                min-width: 70px;
            }

            .react-star {
                color: gray;

                &:active {
                    color: goldenrod;
                }
            }
        }

        .review-input {
            width: 100%;

            .review-text {
                width: 100%;
                padding: 10px;
                border-radius: 5px;
                resize: vertical;
                border: 1px solid #ccc;
                outline: none;

                &:focus-visible {
                    border-color: #888; // Change border color to red when focused
                }

                @include breakpoint(0px) {
                    height: 250px;
                }

                @include breakpoint(769px) {
                    height: 260px;
                }
            }



        }

        .submit-button {
            display: flex;
            flex-direction: row;
            margin: 20px auto;
            justify-content: center;

            .review-submit-button {
                width: 50%;
                padding: 10px 20px;
                background-color: #fff;
                color: #000;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                outline: 1px solid #000;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #000;
                    color: #fff;
                    border: #000;
                    outline: 1px solid #000;
                }
            }
        }
    }
}