@import "../../variables";

.custom-height {
  width: 100%;
  object-fit: cover;

  @include breakpoint(0px) {
    height: $heighMobile;
  }

  @include breakpoint(769px) {
    height: $heightTablet;
  }

  @include breakpoint(1024px) {
    height: $heightDesktop;
  }

  @include breakpoint(1500px) {
    height: $heightLarge;
  }

  .carousel-control-next,
  .carousel-control-prev {
    display: none;
  }
}

.container-fluid {
  margin: 0;
  padding: 0;

  .custom-carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    background-color: #ccc;
    color: #000;
    border: none;
    padding: 15px 1px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease,
      box-shadow 0.3s ease;

    &:hover {
      background-color: #fff;
      box-shadow: 0px 0px 10px #ccc;
    }

    &.prev {
      left: 0;
    }

    &.next {
      right: 0;
    }
  }
}

.slides {
  margin: 0;
  padding: 0;
  position: relative;

  .row {
    margin: 0;
    padding: 0;
    position: relative;

    #carouselExampleCaptions {
      padding-right: 0;
      padding-left: 0;
      margin: 0;
      padding: 0;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .carousel-indicators {
      gap: 10px;

      #custom_crousel_radius {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }

      .active {
        background-color: black;
      }
    }

    .carousel-caption {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      text-align: center;

      h5 {
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: bold;
      }

      p {
        font-size: 16px;
        font-weight: lighter;
      }
    }
  }
}
