.button-base {
  padding: 0 10px;
  font-size: 14px;
  letter-spacing: 0.5px;
  border-radius: 0;
  height: 42px;
}

.primary-btn {
  @extend .button-base;
  background: #000;
  color: #fff;

  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #000;
    width: 30px;
    height: 30px;
    animation: spin 0.7s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  &:hover {
    background: white;
    color: #000;
    transition: 0.3s;
    border: 1px solid #d7d7d7;
    font-weight: 600;
  }
}

.secondary-btn {
  @extend .button-base;
  background: #fff;
  color: #000;

  &:hover {
    background: #000;
    color: #fff;
    transition: 0.3s;
  }
}

.transparent-btn {
  @extend .button-base;
  background: transparent;
  color: #000;

  &:hover {
    background: #000;
    color: #fff;
    transition: 0.3s;
  }
}

.transparent-hover-btn {
  @extend .button-base;
  background: #000;
  color: #fff;

  &:hover {
    background: transparent;
    color: #000;
    transition: 0.3s;
  }
}

.white--btn {
  @extend .button-base;
  background: #fff;
  border: 1px solid rgb(227, 227, 227);
  color: #000;

  &:hover {
    background: rgb(240, 240, 240);
    color: #000;
    transition: 0.5s;
  }
}