@import "../../variables";

.payment-conainer {
  width: 100%;
  height: auto;
  margin-top: 50px;

  .payment-heading {
    font-size: 22px;
    letter-spacing: 1.2px;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 4px;

    @include media-breakpoint-down("md") {
      width: auto;
      gap: 12px;
      margin-bottom: 3px;
    }

    .common-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 4%;

      @include media-breakpoint-down("md") {
        flex-direction: row;
        gap: 2%;
      }

      .custom-input-label {
        letter-spacing: 0.5px;
        font-size: 0.82857143em;
        font-weight: 700;
        margin-bottom: 4px;
      }

      .custom-input {
        height: 40px;
        font-size: 14px;
        letter-spacing: 1px;
        border-radius: 0;
      }

      .common-input {
        width: 100%;

        &::placeholder {
          color: #999;
        }

        &:focus,
        &:active {
          box-shadow: rgba(118, 118, 118, 0.8) 0px 0px 0px 2px;
          outline: none;
          border-color: rgba(118, 118, 118, 0.8);
        }
      }

      .custom-select-container {
        width: 100%;
        height: auto;
        border-radius: 0;

        .custom-select-label {
          letter-spacing: 0.5px;
          font-size: 0.82857143em;
          font-weight: 700;
        }

        .custom-select-input-box {
          height: 40px;
          border-radius: 0;
          justify-content: space-between;

          .selected-option-text {
            font-size: 14px;
            letter-spacing: 1px;
          }

          .custom-dropdown-icon {
            font-size: 13px;
          }

          .custom-options-list {
            top: 100%;
            height: 200px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 5px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #888;
            }

            &::-webkit-scrollbar-track {
              background-color: #f1f1f1;
            }

            .custom-options {
              padding: 8px;
              font-size: 13px;
              letter-spacing: 1px;
            }
          }
        }
      }

      .paypal-button-container {
        width: 100%;

        .paypal-btn {
          width: 40%;
          height: 42px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 0;
          background: #000;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 1.2px;
          transition: background-color 0.3s ease;

          @include media-breakpoint-down("md") {
            width: 90%;
            margin: auto;
          }

          &:focus,
          &:active {
            outline: none;
            box-shadow: none;
          }

          &:hover {
            background-color: #333;
          }
        }
      }
    }

    .radio-container {
      width: 100%;

      .radio-button-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 40px;

        .credit-card-container,
        .paypal-container {
          display: flex;
          flex-direction: row;
          gap: 16px;

          .credit-card-text-radio {
            display: flex;
            flex-direction: row;
            gap: 2px;

            .credit-card-text {
              margin-bottom: 0;
              font-size: 14px;
              font-weight: 700;
              letter-spacing: 0.5px;
            }
          }

          .credit-card-image-container,
          .paypal-image-container {
            width: auto;
            display: flex;
            flex-direction: row;
            gap: 8px;

            .card-container {
              width: 40px;
              height: 20px;
              border: 1px solid rgb(227, 227, 227);

              img {
                width: 100%;
                height: inherit;
              }
            }
          }
        }
      }
    }

    .creditcard-fullData-container {
      .cardNumber-container {
        width: 95%;
      }
    }

    .creditcard-number-container {
      .number-container {
        width: 50%;

        @include media-breakpoint-down("md") {
          width: 100%;
        }
      }
    }

    .month-year-cvc-container {
      display: flex;
      width: 50%;

      .date-container {
        display: flex;
        flex-direction: column;

        @include breakpoint(0px) {
          width: 50%;
        }

        @include breakpoint(769px) {
          width: 50%;
        }
      }

      .month-container,
      .year-container,
      .cvc-container {
        display: flex;
        flex-direction: column;

        @include breakpoint(0px) {
          width: 50%;
        }

        @include breakpoint(769px) {
          width: 50%;
        }
      }
    }

    .save-payment-button {
      width: 20%;
      border-radius: 4px;
      outline: none;
      box-sizing: border-box;
      padding: 0.5rem 1rem;
      background-color: #000;
      color: #fff;
      font: 300 0.8rem "Arial, Helvetica, sans-serif";
      cursor: pointer;
      transition: all 0.5s;

      &:hover {
        background-color: #fff;
        color: #000;
      }

      @include media-breakpoint-down("md") {
        font: 300 1rem "Arial, Helvetica, sans-serif";
        padding: 2rem;
      }
    }
  }
}
