@import "../variables";

._navbar {
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 90px;
  background: rgb(248, 249, 250);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  padding: 0 20px;

  .panel-heading {
    width: auto;
    height: auto;
    margin-left: 8%;

    h2 {
      font-size: 32px;
      font-weight: 600;
      letter-spacing: 1.5px;
      color: #000;
      margin-bottom: 0;
    }
  }

  .profile-search-container {
    display: flex;
    flex-direction: row;

    .avatar-dropdown-toggle {
      position: relative;

      .avatar {
        width: 38px;
        height: 38px;
        object-fit: cover;
        border-radius: 50%;
        cursor: pointer;
      }

      .green-dot {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: green;
      }

      ._dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        margin-top: 4px;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        z-index: 1;
        transform-origin: top;
        transform: scaleY(0);
        transition: transform 0.3s ease-in-out;
        visibility: hidden;

        &.show {
          transform: scaleY(1);
          visibility: visible;
        }

        .dropdown-item {
          color: #333;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          padding: 8px;
          text-decoration: none;

          &:hover {
            background-color: #f0f0f0;
          }

          &:active,
          &:focus {
            background-color: #f0f0f0;
            outline: none;
          }
        }
      }
    }
  }
}
