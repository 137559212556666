@import "../variables";

* {
  font-family: $font-family-primary;
}

.navbar {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  background: #fff;
  z-index: 9999;

  .container-fluid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 32px;
    padding-left: 32px;

    @include media-breakpoint-down(md) {
      padding-right: 20px;
      padding-left: 20px;
    }
    @include media-breakpoint-down(sm) {
      padding-right: 10px;
      padding-left: 10px;
    }

    .logo-container {
      z-index: 9999;

      @include media-breakpoint-down(lg) {
        order: 1;
        display: flex;
        flex-direction: row;
        gap: 10px;
      }

      @include media-breakpoint-down(md) {
        z-index: 2;
      }

      @include media-breakpoint-down(sm) {
        z-index: 2;
        gap: 5px;
        align-items: end;
      }

      .navbar-brand {
        z-index: 9999;
        margin: 0;
        display: flex;

        h2 {
          font-size: 14px;
          color: #000;
          // text-transform: uppercase;
          letter-spacing: 0.8px;
          flex-direction: row;
          gap: 5px;
          margin: auto;

          @include media-breakpoint-up(md) {
            font-size: 18px;
          }
        }
      }
    }

    .navbar-toggler {
      border: none;
      display: block;

      @include media-breakpoint-down(lg) {
        padding-right: 0;
        padding-left: 0;
      }

      @include media-breakpoint-up(lg) {
        display: none;
        // order: 1;
      }

      &:focus {
        box-shadow: none;
        border: none;
      }

      .navbar-toggler-icon {
        width: 23px;
        height: 23px;

        @include media-breakpoint-up(md) {
          width: 28px;
          height: 28px;
        }
      }
    }

    .custom-navbar-nav {
      flex-direction: column;
      display: none;

      @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
      }

      @include breakpoint(1400px) {
        gap: 32px;
      }

      li {
        z-index: 1;

        @include media-breakpoint-up(xl) {
          padding-right: 30px;
        }

        .custom-dropdown {
          @include media-breakpoint-up(md) {
            position: initial;
          }

          .down-icon-container {
            display: flex;
            align-items: center;

            a {
              display: flex;
              color: #000;
              font-size: 12px;
              font-weight: 700;
              text-transform: uppercase;
              letter-spacing: 1px;
              align-items: center;
              padding-right: 3px;
              border: none;
              @include media-breakpoint-down(xl) {
                font-size: 11px;
              }

              &:focus,
              &:active {
                box-shadow: none;
                border: none;
                outline: none;
              }

              &::after {
                content: none;
                border: none;
                outline: none;
              }
            }

            .md-arrow-down-icon {
              display: block;
              margin-left: 0px;
              font-size: 11px;
              cursor: pointer;

              @include media-breakpoint-down(xl) {
                display: block;
                margin-left: 4px;
                margin-top: 0;
                font-size: 9px;
                margin-bottom: 2px;
              }
            }
          }

          .dropdown-menu {
            padding-top: 30px;
            position: relative;
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

            .dropdown-main-row {
              display: flex;
              flex-direction: row;
              gap: 70px;

              @include breakpoint(1300px) {
                gap: 100px;
              }

              @include breakpoint(1600px) {
                gap: 150px;
              }

              .dropdown-main-columns {
                width: auto;

                .dropdown-internal-row {
                  .dropdown-internal-columns {
                    padding: 0;
                    width: auto !important;

                    ul {
                      list-style: none;
                      padding: 0;

                      li {
                        margin-bottom: 15px;
                        padding-right: 0;

                        a {
                          font-size: 12px;
                          font-weight: 400;
                          text-transform: capitalize;
                          letter-spacing: 0.5px;
                          padding: 0 10px;

                          &:focus,
                          &:active {
                            border: none;
                            background: none;
                            outline: none;
                            box-shadow: none;
                          }

                          &:hover {
                            color: #000;
                            text-decoration: underline;
                            box-shadow: none;
                            background-color: transparent;
                          }
                        }
                      }
                    }
                  }
                }
              }
              .dropdown-image-columns {
                width: auto;

                .dropdown-img {
                  display: flex;
                  justify-content: flex-end;

                  .image-container {
                    width: 230px;
                    height: 200px;

                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }
            }
          }

          &.dropdown:hover > .dropdown-menu {
            display: block;
            position: absolute;
            top: 61%;
            z-index: 1;
            width: 100%;
            border: none;
            left: 0;

            .dropdown-main-row {
              width: 70%;
            }
          }

          &.dropdown > .dropdown-toggle:active {
            /*Without this, clicking will make it sticky*/
            pointer-events: none;
          }

          &.dropdown > .dropdown-toggle::after {
            content: none;
          }
        }

        .men-dropdown {
          &:hover {
            .dropdown-main-row {
              transform: translateX(40%);
            }
          }
        }

        .women-dropdown {
          &:hover {
            .dropdown-main-row {
              transform: translateX(53%);
            }
          }
        }

        .kids-dropdown {
          &:hover {
            .dropdown-main-row {
              transform: translateX(67%);
            }
          }
        }

        a {
          color: #000;
          font-size: 12px;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 1px;

          @include media-breakpoint-down(xl) {
            font-size: 11px;
          }

          &:focus {
            box-shadow: none;
            border: none;
          }

          &::after {
            content: none;
          }
        }
      }
    }

    .icons-container {
      align-items: center;
      z-index: 99999999;
      display: none;

      @include media-breakpoint-down(lg) {
        order: 3;
        z-index: 2;
      }

      @include media-breakpoint-down(md) {
        gap: 10px;
        align-items: center;
      }

      @include media-breakpoint-up(lg) {
        display: flex;
      }

      @include breakpoint(1300px) {
        display: block;
        width: auto;
        justify-content: baseline;
        padding-right: 0;
      }

      ul {
        list-style: none;
        display: flex;
        gap: 30px;
        padding-bottom: 0;
        margin-bottom: 0;

        @include media-breakpoint-down(lg) {
          gap: 20px;
          padding: 0;
        }

        @include media-breakpoint-down(md) {
          gap: 10px;
          padding: 0;
        }
        @include media-breakpoint-down(sm) {
          gap: 5px;
        }

        li {
          a {
            cursor: pointer;

            .icon {
              width: 25px;
              height: 25px;
              color: #000;

              @include media-breakpoint-down(md) {
                width: 25px;
                height: 25px;
              }
            }
          }

          .language-dropdown {
            position: relative;

            .dropdown-toggle {
              cursor: pointer;

              &::after {
                content: none;
              }
            }
          }

          .dropdown-menu {
            display: none;
            position: absolute;
            top: 100%;
            right: 0;
            margin-top: 5px;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          .open {
            .dropdown-menu {
              display: block;
            }
          }

          .dropdown-item {
            cursor: pointer;
            color: #000;
            font-size: 11px;
            text-transform: uppercase;
            letter-spacing: 1px;
            padding: 8px 16px;

            &:active,
            &:focus {
              background-color: transparent;
              outline: none;
              box-shadow: none;
            }
          }
        }

        .serach-wrapper {
          display: flex;
          position: relative;
        }

        .login-clicked {
          pointer-events: none;
        }
      }
    }

    .medium-search {
      position: absolute;
      width: 100%;
      left: 0;
      top: 100%;
      padding-top: 5px;

      @include media-breakpoint-up(xl) {
        display: none;
      }

      &.open {
        display: flex;
        flex-direction: row;
        justify-content: center;
        .searchContainer {
          width: 50%;
          background: #ffffff;
          padding-left: 2px;
          border: 2px solid black;

          .searchBox {
            line-height: normal;
            width: 100%;
            height: 42px;
            font-weight: 500;
            letter-spacing: 1px;
            border: none;

            input {
              width: 100%;
              height: 100%;
              padding-left: 10px;
              font-size: 12px;
              border: none;
              &:active,
              &:focus {
                border: none;
                outline: none;
              }
            }

            button {
              position: absolute;
              right: 27%;
              top: 50%;
              background-color: transparent;
              width: auto;
              height: auto;
              padding: 0;
              margin: auto;
              z-index: 1;
              border: none;
              transform: translate(-27%, -50%);

              .icon {
                width: 26px;
                height: 25px;
                color: #000;
                cursor: pointer;
              }
            }
          }
          .close-icon {
            position: absolute;
            right: 25%;
            transform: translateX(-25%);
            width: 25px;
            height: 25px;
            color: #000;
            cursor: pointer;
          }

          .suggestions-dropdown {
            position: absolute;
            top: 100%;
            left: 50%;
            width: 50%;
            background-color: white;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            border: 2px solid #ddd;
            max-height: 200px;
            overflow-y: auto;
            transform: translatex(-50%);
            padding-left: 2px;

            ul {
              list-style: none;
              padding: 0;
              margin: 0;

              li {
                padding: 10px;
                color: black;
                cursor: pointer;
                transition: background-color 0.3s ease;
                font-size: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:hover {
                  background-color: #f0f0f0;
                  cursor: pointer;
                  z-index: 1000000;
                }
                .suggestion-name {
                  flex-grow: 1;
                }
                .suggestion-icon {
                  margin-left: 10px;
                }
              }
            }

            @include media-breakpoint-down(lg) {
              width: 100%;
            }
          }
          &:active,
          &:focus {
            box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 2px 2px;
          }
        }

        .close-icon {
          position: absolute;
          top: 13.5px;
          width: 25px;
          height: 25px;
          color: #000;
          cursor: pointer;
        }

        input::-webkit-input-placeholder,
        textarea::-webkit-input-placeholder {
          color: black;
          font-weight: 400;
          letter-spacing: 0.5px;
          font-size: 14px;
        }
      }
    }

    .offcanvas {
      padding: 22px;

      .btn-close {
        padding: 0;
        margin: 0;

        &:focus,
        &:active {
          box-shadow: none;
          outline: none;
          border: none;
        }
        @include media-breakpoint-down(md) {
          font-size: 18px;
        }
      }

      .offcanvas-header {
        padding: 0;
      }

      .offcanvas-body {
        padding: 20px 0;
      }

      &.offcanvas-end {
        @include breakpoint(767px) {
          width: 70%;
        }
      }
    }
  }

  .input-container {
    display: flex;
    justify-content: center;
    width: 100%;
    order: 4;
    margin-top: 12px;
    padding: 0 5px;

    @include media-breakpoint-down(md) {
      margin-top: 10px;
    }

    .searchContainer {
      width: 100%;
      position: relative;
      .searchBox {
        width: 60%;
        position: relative;
        @include media-breakpoint-down(lg) {
          width: 100%;
        }

        input {
          width: 100%;
          height: 42px;
          border-radius: 0;
          // border-top-right-radius: 3px !important;
          // border-bottom-right-radius: 3px !important;

          z-index: 1;
          &:focus,
          &:active {
            border: none;
            outline: none;
            box-shadow: rgba(118, 118, 118, 0.8) 0px 0px 0px 3px;
          }

          @include media-breakpoint-down(md) {
            height: 38px;
          }
        }

        button {
          position: absolute;
          right: 2%;
          top: 50%;
          background-color: transparent;
          width: auto;
          height: auto;
          padding: 0;
          margin: auto;
          z-index: 1;
          border: none;
          transform: translate(-2%, -50%);

          .icon {
            width: 25px;
            height: 25px;
            color: #000;
            cursor: pointer;
          }
        }
      }
      .close-icon {
        display: none;
      }
    }
    .suggestions-dropdown {
      position: absolute;
      top: 100%;
      left: 50%;
      width: 50%;
      background-color: white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border: 2px solid #ddd;
      max-height: 200px;
      overflow-y: auto;
      transform: translatex(-50%);

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          padding: 10px;
          color: black;
          cursor: pointer;
          transition: background-color 0.3s ease;
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:hover {
            background-color: #f0f0f0;
            cursor: pointer;
            z-index: 1000000;
          }
          .suggestion-name {
            flex-grow: 1;
          }
          .suggestion-icon {
            margin-left: 10px;
          }
        }
      }

      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
  }
}
