@import "../../variables";

.review-products {
    width: 100%;

    .review-products-heading-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 26px;

        @include breakpoint(0px) {
            flex-direction: column;
        }

        @include breakpoint(545px) {
            flex-direction: row;
        }

        .review-product-text {
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 0 0 5px;
            line-height: 1.5;
        }

        .review-section {
            display: flex;
            flex-direction: row;
            gap: 20px;

            .review-rating {
                font-size: 26px;
                letter-spacing: 0.8px;
                text-transform: uppercase;
                font-weight: 700;
            }

            .review-number-main {
                display: flex;
                flex-direction: column;

                .react-star {
                    color: gray;

                    &:active {
                        color: goldenrod;
                    }
                }

                .review-number-text {
                    font-size: 14px;
                    letter-spacing: 0.8px;
                    color: #878787;
                }
            }
        }
    }

    .break-line::after {
        content: "";
        display: block;
        height: 1px;
        opacity: 0.5;
        background: #ccc !important;
        width: 100%;
        margin: 10px auto 20px auto;
    }

    .reviews-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 26px;
        margin-bottom: 20px;

        .review-item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .review-main {
                display: flex;
                justify-content: space-between;

                @include breakpoint(0px) {
                    flex-direction: column;
                }

                @include breakpoint(769px) {
                    flex-direction: row;
                }

                .reviewer {
                    display: flex;

                    .reviewer-name {
                        display: flex;
                        width: 100%;
                        font-size: 14px;
                        letter-spacing: 0.8px;
                        text-transform: uppercase;
                        font-weight: 700;
                    }
                }
            }

            .rating-display-area {
                display: flex;
                flex-direction: column;

                .review-rating {
                    color: goldenrod;
                }

                .review-comment {
                    font-size: 14px;
                    letter-spacing: 0.8px;
                    color: #878787;
                }
            }
        }
    }

    .pagination {
        display: flex;
        justify-content: center;
        margin: 20px 0;

        .pagination-btn {
            padding: 5px 10px;
            margin: 0 5px;
            cursor: pointer;
            background-color: transparent;
            border: 1px solid #ccc;
            color: #222;

            &:hover {
                background-color: #000;
                color: #fff;
            }

            &.active {
                background-color: #000;
                color: #fff;
            }

            &:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }

        @media (max-width: 359px) {
            .pagination-btn:not(:nth-child(-n+2)):not(:last-child) {
                display: none;
            }

            .pagination-btn:nth-child(3):before {
                content: "...";
                display: inline-block;
                margin: 0 5px;
            }
        }
    }
}