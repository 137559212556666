@import "../variables";

.table_container {
  .serach-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    input {
      width: 20%;
      height: 36px;
      border-radius: 0;

      @include media-breakpoint-down(lg) {
        width: 25%;
      }

      @include media-breakpoint-down(md) {
        width: 40%;
      }
    }
  }
  .custom-data-table {
    .ebEgOr {
      .rdt_Table {
        .rdt_TableHead {
          .rdt_TableHeadRow {
            .rdt_TableCol {
              .rdt_TableCol_Sortable {
                .bozQpI {
                  font-size: 16px;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }
  }
}
