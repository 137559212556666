@import "../variables";
.cart-product-card {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin: 20px 0px;
  padding: 15px;
  border-radius: 8px;
  position: relative;

  .product-image-container {
    margin-right: 20px;

    .product-image {
      max-width: 120px;
      max-height: 150px;
      object-fit: contain;
    }
  }

  .product-details {
    flex-grow: 1;

    .product-name {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0px;
    }

    .details {
      display: flex;
      align-items: center;
      margin-bottom: 0px;

      .details-label {
        margin-right: 10px;
        font-size: 12px;
      }

      .details-box {
        width: 18px;
        height: 18px;
        border: 1px solid #ddd;
        margin-right: 10px;
      }

      .details-name {
        font-size: 12px;
      }
    }

    .edit-link {
      font-size: 12px;

      a {
        color: #007bff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .product-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .cancel-icon {
      cursor: pointer;
      font-size: 20px;
      margin-bottom: 10px;
      position: absolute;
      top: 0;
      right: 0;
    }

    .product-labels {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;

      .labels-row,
      .values-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 5px;
        gap: 15px;
        font-size: 12px;
      }
      .values-row {
        font-size: 14px;
        color: black;
        font-weight: 600;
      }
      .quantity-control {
        select {
          padding: 3px;
          font-size: 12px;
          border: 1px solid #ddd;
          color: #333;
          cursor: pointer;
          background-color: #fff;
          border-radius: 4px;
          transition: border-color 0.3s, color 0.3s;

          &:hover,
          &:focus {
            border-color: #000000;
            color: #000000;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down("sm") {
  .cart-product-card {
    .product-image-container {
      .product-image {
        max-width: 18vw;
        max-height: 150px;
        object-fit: contain;
      }
    }
  }
}
