@import "../variables";

.footer {
  padding-top: 4rem;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  background: #000;
  color: #777;

  @include media-breakpoint-down("md") {
    padding-top: 3rem;
  }

  .footer-container {
    .footer-first-row {
      padding: 0;
      margin: 0;

      @include media-breakpoint-down("md") {
        padding: 0 6vw;
      }

      .brand-name-col {
        @include media-breakpoint-down("md") {
          padding: 0;
        }

        .footer-site-logo {
          color: #fff;
          display: flex;
          text-decoration: none;
          gap: 12px;

          @include media-breakpoint-up("xl") {
            line-height: 34px;
            margin-bottom: 15px;
          }

          .dc-text {
            width: auto;

            &:first-child {
              font-size: 40px;
            }

            &:nth-child(2) {
              font-size: 40px;
            }
          }

          .tech-text {
            width: auto;
            text-transform: uppercase;
            font-size: 16px;
            letter-spacing: 1px;

            &::first-letter {
              font-size: 40px;
            }
          }
        }

        p {
          font-size: 12px;
          letter-spacing: 0.5px;
          line-height: 20px;
          width: 85%;
          color: #ccc;

          @include media-breakpoint-down("md") {
            width: 100%;
          }
        }

        .terms-container {
          margin-top: 1.5rem;

          .link-menu {
            display: block;

            li {
              a {
                color: #ccc;
                display: inline-block;
                padding: 5px 0;
                text-decoration: none;

                &:hover {
                  color: #fff;
                  text-decoration: underline;
                  transition: 0.2s;
                }
              }
            }
            &.nav-left {
              li {
                &:first-child {
                  a {
                    padding-left: 0;
                  }
                }
              }
            }
            &.nav-right {
              li {
                &:last-child {
                  a {
                    padding-left: 0;
                  }
                }
              }
            }
          }
        }
      }

      .footer-main-links-container {
        padding-top: 10px;

        @include media-breakpoint-down("md") {
          margin-top: 1rem;
        }

        .row {
          .footer-links-col-one,
          .footer-links-col-second {
            @include media-breakpoint-down("md") {
              padding: 0;
            }

            h3 {
              color: #fff;
              font-size: 16px;
              margin-bottom: 20px;
            }
            .links {
              li {
                margin-bottom: 10px;
                a {
                  color: #ccc;
                  text-decoration: none;
                  font-size: 12px;
                  letter-spacing: 0.5px;

                  &:hover {
                    color: #fff;
                    text-decoration: underline;
                  }
                }
              }
            }
          }

          .footer-links-col-three {
            .join-us {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              @include media-breakpoint-down("lg") {
                padding: 30px 0;
                width: 100%;
                gap: 30px;
              }

              @include breakpoint(1300px) {
                width: 75%;
                gap: 60px;
              }

              @include breakpoint(992px) {
                width: 100%;
                gap: 60px;
              }

              @include breakpoint(768px) {
                width: 100%;
                gap: 60px;
              }

              .join-us-button-container {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;

                .btn-join {
                  width: 50%;
                  height: 40px;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  gap: 10px;
                  background: #fff;
                  border-radius: 0;
                  font-size: 16px;
                  letter-spacing: 0.5px;
                  color: #000;

                  a {
                    text-decoration: none;
                    list-style: none;
                    color: black;

                    &:hover {
                      color: #fff;
                    }
                  }
                  @include media-breakpoint-down("lg") {
                    width: 70%;
                  }

                  @include media-breakpoint-down("md") {
                    width: 100%;
                  }

                  &:hover {
                    background: transparent;
                    border: 1px solid #fff;
                    color: #fff;
                    transition: 0.2s;

                    a {
                      color: #fff;
                    }
                  }

                  .arrow-icon {
                    font-size: 20px;
                    margin-top: 3px;
                  }
                }
              }

              .subscribe-warapper {
                display: flex;
                flex-direction: column;

                .subscribe-text {
                  font-size: 14px;
                  color: #ccc;
                  letter-spacing: 0.5px;
                  line-height: 20px;
                }

                .subscribe-conatiner {
                  width: 100%;
                  border: 1px solid #fff;
                  position: relative;

                  input {
                    width: 100%;
                    border-radius: 0;
                    height: 40px;
                  }

                  button {
                    background: #000;
                    color: #ccc;
                    font-size: 14px;
                    letter-spacing: 0.5px;
                    border-radius: 0;
                    height: 40px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 999;

                    @include media-breakpoint-down("lg") {
                      width: 40%;
                      font-size: 12px;
                    }

                    @include media-breakpoint-down("md") {
                      width: 30%;
                    }

                    &:hover {
                      color: #fff;
                      transition: 0.2s;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .footer-second-row {
      .footer-second-row-col-one {
        .line {
          border-top: 1px solid rgba(#fff, 0.2);
        }
      }
    }

    .footer-icons-wrraper {
      .center-content {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        p {
          font-size: 14px;
          font-weight: 400;
        }

        .footer-icons-container {
          .social {
            display: flex;
            gap: 20px;

            li {
              display: inline-block;
              position: relative;
              a {
                position: relative;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                display: inline-block;
                margin: 0;
                padding: 0;
                background-color: lighten(#ccc, 10%);
                color: darken(#ccc, 60%);
                align-items: center;
                display: flex;
                justify-content: center;

                > span {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
                &:hover {
                  color: #000;
                }

                .footer-icon {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
