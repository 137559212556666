@import "../../../variables";

.reset_password_conatainer_fluid {
  .reset_password_conatainer {
    padding-top: 60px;
    padding-bottom: 60px;

    @include media-breakpoint-down(md) {
      padding-top: 90px;
      padding-bottom: 50px;
    }

    .heading-reset {
      font-size: 28px;
      letter-spacing: 1.5px;
      line-height: 18px;
      font-weight: 600;
      color: #000;
      text-align: center;
      margin-bottom: 50px;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .reser-password-wrapper {
      width: 35%;
      margin: auto;

      @include breakpoint(0px) {
        width: 100%;
        padding: 0 15px;
      }

      @include breakpoint(769px) {
        width: 50%;
        padding: 0;
      }

      @include breakpoint(1280px) {
        width: 45%;
      }

      @include breakpoint(1500px) {
        width: 40%;
      }

      .reser-password-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;

        @include media-breakpoint-down(md) {
          gap: 20px;
        }

        .newPassword-input-container,
        .confirmPassword-input-container {
          position: relative;

          .custom-input {
            border: 1px solid rgb(227, 227, 227);
            border-radius: 0;
            font-size: 14px;
            letter-spacing: 0.8px;
            height: 42px;

            &:focus,
            &:active {
              box-shadow: rgba(118, 118, 118, 0.8) 0px 0px 0px 3px;
            }
          }
          .custom-password-icon-container {
            top: 40px;
            right: 10px;

            .custom-eye-icon {
              width: 22px;
              height: 22px;
              color: rgb(0, 0, 0);
            }
          }
        }

        .password-description-container {
          width: 100%;
          padding: 0 10px;

          .password-description-text {
            font-size: 11.5px;
            text-align: justify;
            letter-spacing: 0.3px;
            color: #000;
            line-height: 18px;
            margin-bottom: 0;
          }
        }

        .reset-button-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 10px;

          @include media-breakpoint-down(md) {
            flex-direction: column;
            justify-content: flex-start;
            gap: 20px;
          }

          .save-button,
          .cancel-button {
            width: 49%;
            height: 42px;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 1.8px;
            text-transform: uppercase;
            border: 1px solid #d7d7d7;

            @include media-breakpoint-down(md) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
